<template>
  <div style="height: 100%; width: 100%; display: flex; place-content: center; place-items: center; padding: 12px;">
    <div style="text-align: center;">
      <h3 class="primary--text">คุณไม่มีสิทธิ์ใช้หน้านี้</h3>
      <br/>
      รหัสตรวจสอบ <br/>
      <span style="word-break: break-all;">{{ code }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Forbidden',
  computed: {
    code () {
      return window.btoa(JSON.stringify(this.$route.params))
    }
  }
}
</script>

<style scoped>

</style>
